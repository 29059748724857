/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: black;
  color: white;
}

/* Header styles */
header {
  background-color: black;
  padding: 20px;
  text-align: center;
}

h1 {
  color: #29648a;
}

/* Button styles */
button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: darkgreen;
}

/* Link styles */
a {
  color: green;
  text-decoration: none;
}

a:hover {
  color: darkgreen;
}

/* Alert styles */
.alert {
  background-color: rgba(108, 239, 32, 0.77);
  color: white;
  padding: 10px;
  border-radius: 4px;
  border-width: 0;
}

.alert-danger {
  background-color: darkred;
}

.alert-warning {
  background-color: rgba(255, 255, 0, 0.793);
  color: black;
}

.btn-primary {
  border: 2px solid #2e9cca; /* Border color for the primary button */
  background-color: transparent; /* Transparent background */
  color: #2e9cca; /* Text color for the primary button */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
}
/* Style for the primary button on hover */
.btn-primary:hover {
  background-color: #2e9cca;
  color: white;
}

.btn-secondary {
  border: 1px solid #fbfbfb; /* Border color for the primary button */
  background-color: transparent;
  color: "#00000";
  font-size: 10px;
}
.btn-secondary:hover {
  background-color: rgb(0, 139, 30);
}

.btn-tertiary {
  border: 2px solid darkred; /* Border color for the primary button */
  background-color: rgb(80, 7, 7);
  color: #fbfbfb;
  font-size: 10px;
}
.btn-tertiary:hover {
  background-color: darkred;
}

.btn-quaternary {
  border: 2px solid green; /* Border color for the primary button */
  background-color: rgba(0, 128, 0, 0.387);
  color: #fbfbfb;
  font-size: 10px;
}
.btn-quaternary:hover {
  background-color: green;
}
/* Card styles */
.card {
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Input styles */
input {
  background-color: black;
  color: white;
  border: 1px solid green;
  border-radius: 4px;
  padding: 10px;
}

/* Customize other elements as needed based on your design requirements */

.header-nav {
  background-color: black;
  color: white;
  font-size: 20px;
}

.header-nav .nav-link {
  color: #aaabb8;
  font-size: 18px; /* Adjust the font size to your preference */
  padding: 10px 20px;
}

.header-nav .nav-link.active {
  background-color: #29648a;
  color: white;
}

.my-dropdown {
  background-color: black;
  color: white;
  font-size: 18px;
  margin-right: 20px; /* Adjust the spacing to your preference */
}

.my-dropdown .nav-link {
  color: #aaabb8;
  padding: 10px 20px;
}

.my-dropdown .nav-link.active {
  background-color: #29648a;
  color: white;
}

.my-dropdown-menu {
  background-color: black;
  color: white;
  border: none;
  font-size: 18px;
  border-radius: 0;
  box-shadow: none;
}

.my-dropdown-menu .dropdown-item {
  color: #aaabb8;
  padding: 10px 20px;
}

.my-dropdown-menu .dropdown-item.active {
  background-color: #29648a;
  color: white;
}

/* Accordion styles */
.accordion {
  width: 80%;
  margin: 0 auto; /* To horizontally center the accordion */
}

.accordion-form-body {
  padding: 200px; /* Adjust padding as needed to maintain the original form's shape */
}

/* Customize the accordion header button */
.accordion-button {
  color: #ffffff;
  background-color: #29648a; /* New blue color */
  border: none; /* Remove the border */
  border-radius: 0; /* Remove the border radius */
  padding: 15px 20px; /* Adjust padding as needed */
  font-size: 18px; /* Adjust font size as needed */
}

/* Style for the accordion header button on hover */
.accordion-button:hover {
  background-color: #265b7a; /* Slightly darker shade of blue on hover */
}

/* Style for the active (expanded) accordion header button */
.accordion-button:not(.collapsed) {
  background-color: #265b7a; /* Slightly darker shade of blue for active header */
}

/* Dark theme for the accordion headers */
.accordion .accordion-header {
  background-color: #333;
  color: #fff;
  border: none; /* Remove the border */
  border-radius: 0; /* Remove the border radius */
  padding: 15px 20px; /* Adjust padding as needed */
  font-size: 18px; /* Adjust font size as needed */
  cursor: pointer;
}

/* Style for the active (expanded) accordion header */
.accordion .accordion-header:not(.collapsed) {
  background-color: #444;
}

/* Dark theme for the accordion bodies */
.accordion .accordion-body {
  background-color: #444;
  color: #fff;
  padding: 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
}
